<template>
  <v-footer dark padless>
    <v-card flat tile class="secondary white--text text-center">
      <v-card-text>
        <v-btn
          v-for="(icon, i) in icons"
          :key="i"
          class="mx-4 white--text"
          :href="icon.link"
          target="_blank"
          icon
        >
          <v-icon size="24px">{{ icon.text }}</v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
        <a href="https://drive.google.com/file/d/1cHpHwH2FdRnvncFUg_aDER02SWQjzV14/view" target="_blank">Aviso de privacidad</a>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>POLANCORP</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<style scoped>
.v-card {
  width: 100%;
}
</style>

<script>
export default {
  data: () => ({
    icons: [
      {
        text: "mdi-facebook",
        link: "https://www.facebook.com/Polancorp-Bienes-Raices-Tuxtla-105895891402790/",
      },
      {
        text: "mdi-instagram",
        link: "https://instagram.com/polancorp.bienes.raices?igshid=YmMyMTA2M2Y=",
      },
    ],
  }),
};
</script>

<template>
  <section id="download">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="center">
            <v-col sm="4" class="hidden-xs-only">
              <v-img src="@/assets/img/casas3.png" class="d-block ml-auto mr-auto" max-width="350px" />
            </v-col>
            <v-col cols="12" sm="8" class="white--text text-left">
              <h1 class="font-weight-light display-2 mb-2">Propiedades</h1>
              <h1 class="font-weight-light">
                Tenemos una cartera amplia de propiedades, te ayudamos a tramitar tu crédito INFONAVIT, FOVISSSTE o BANCARIO. 
                <br>
                Ponte en contacto con uno de nuestros asesores y recibe todas las opciones de propiedades que tenemos pra ti.
              </h1>
              <!--<v-btn rounded outlined href="" target="_blank" large color="white" class="mt-4">
                <v-icon class="mr-2">
                  mdi-home
                </v-icon>
                Ver propiedades
              </v-btn>-->
            </v-col>
            <v-col cols="12" sm="8" md="6" lg="5" class="white--text text-left">
              <v-carousel
                cycle
                height="500"
                hide-delimiter-background
                show-arrows-on-hover
              >
                <v-carousel-item
                  v-for="(item,i) in items"
                  :key="i"
                  :src="item.src"
                ></v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
  export default {
    data () {
      return {
        items: [
          {
            src: require('@/assets/img/slide1.jpeg'),
          },
          {
            src: require('@/assets/img/slide2.jpeg'),
          },
          {
            src: require('@/assets/img/slide3.jpeg'),
          },
          {
            src: require('@/assets/img/slide4.jpeg'),
          },
          {
            src: require('@/assets/img/slide5.jpeg'),
          },
        ],
      }
    },
  }
</script>
<style scoped>
#download {
  background-image: url("~@/assets/img/bgDownload.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 1000px;
}

#download .container,
#download .row {
  height: 100%;
}
</style>